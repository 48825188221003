<template>
	<div>
		<pui-datatable
			:modelName="modelName"
			:actions="actions"
			:modelColumnDefs="modelColumnDefs"
		></pui-datatable>
		<mrescondicion-modals
			:modelName="modelName"
		></mrescondicion-modals>
	</div>
</template>

<script>
import mrescondicionActions from './MrescondicionActions';
import mrescondicionModals from './MrescondicionModals.vue';

export default {
	name: 'mrescondicion-grid',
	components: {
		'mrescondicion-modals': mrescondicionModals
	},
	data() {
		return {
			modelName: 'mrescondicion',
			actions: mrescondicionActions.gridactions,
			modelColumnDefs: {
			}
		}
	}
}
</script>
